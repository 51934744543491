@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.videosList {
  padding-bottom: 50px;

  .list {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 10px);
    margin: 30px 5px;
  }

  @include breakpoint(768px) {
    .item {
      width: 50%;
      margin: 30px 0;
    }
  }

  @include breakpoint(480px) {
    .item {
      width: 100%;
    }
  }
}
