@use "../../../styles/variables" as *;
.carousel-item {
  width:100%;
}
.productGallery {
  .images {
    img {
      width: auto;
      max-height: 360px;
    }
  }
 
  :global {
    .carousel-slider {
      padding-bottom: 30px;
      border-bottom: 1px solid $line-separator;
    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      iframe {
        margin: 0!important;
        width: 100%!important;
      }
    }

    .thumbs-wrapper {
      margin: 25px 0 0 0;
    }

    .thumbs {
      display: flex;
    }

    .thumb {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      padding: 0;
      border: 3px solid #fff;

      &.selected,
      &:hover {
        border: 3px solid $primary-color;
      }

      img {
        max-height: 55px;
      }
    }
  }
}
