@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.home {
  .featureText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 20px;
    line-height: 36px;
    font-size: 32px;
    font-weight: $font-weight-title;
    text-align: center;

    p {
      color: $primary-color;
    }
  }

  .featureImage {
    display: flex;
    justify-content: flex-end;
    margin-top: -30px;

    img {
      max-width: 90%;
    }
  }

  .products {
    .title {
      line-height: 38px;
      letter-spacing: -1px;
      text-align: center;

      h2 {
        font-size: 32px;
        color: $primary-color;
        font-weight: $font-weight-title;
      }

      p {
        font-size: 25px;
        font-weight: $font-weight-normal;
      }
    }
  }

  .waterBg {
    padding-bottom: 50px;
    background: url("../../assets/images/water-left.jpg") 0px 520px no-repeat,
      url("../../assets/images/water-right.jpg") 100% 520px no-repeat;
  }

  .whereToFindTop {
    margin-top: 40px;
  }

  .post {
    margin-top: 50px;
    border-top: 20px solid $primary-color;
  }

  @include breakpoint(580px) {
    .featureText {
      padding: 20px;
      line-height: 28px;
      font-size: 24px;
    }
  }
}
