@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.whereToFinde {
  background-color: $primary-color;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .infos {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .texts {
    display: flex;
    flex-direction: column;
  }

  .button {
    font-size: 14px;
    color: #ffffff;

    .icon {
      margin-right: 15px;
      font-size: 22px;
    }
  }

  &.primary {
    .title {
      display: flex;
      flex-direction: column;
      max-width: 350px;
      font-size: 26px;
      font-weight: $font-weight-title;
      line-height: 30px;
      color: #ffffff;

      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 5px;
        margin-top: 7px;
        background-color: #fff;
      }
    }
  }

  &.secondary {
    padding: 70px 0;
    background: none;

    .container {
      flex-direction: column;
    }

    .infos {
      flex-direction: column;
      align-items: center;
    }

    .title {
      line-height: 34px;
      text-align: center;
      font-size: 30px;
      font-weight: $font-weight-title;
      color: #000;
    }
    .subtitle {
      line-height: 34px;
      text-align: center;
      font-size: 30px;
      font-weight: $font-weight-title;
      color: $primary-color;

      &::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 24px;
        height: 5px;
        margin-top: 5px;
        background-color: #000;
      }
    }

    .button {
      margin-top: 30px;
    }
  }

  &.tertiary {
    padding: 70px 0;
    background: none;

    .texts {
      max-width: 480px;
    }

    .title {
      line-height: 34px;
      text-align: left;
      font-size: 30px;
      font-weight: $font-weight-title;
      color: #000;
    }
    .subtitle {
      line-height: 34px;
      text-align: left;
      font-size: 30px;
      font-weight: $font-weight-title;

      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 5px;
        margin-top: 5px;
        background-color: #000;
      }
    }
  }

  @include breakpoint(960px) {
    &.primary {
      .infos {
        flex-direction: column;
        align-items: flex-end;
        padding: 20px 0;
      }

      .title {
        margin-bottom: 10px;
        text-align: right;

        &::after {
          margin-left: auto;
        }
      }
    }

    &.tertiary {
      .infos {
        flex-direction: column;
      }

      .texts {
        max-width: none;
      }

      .title {
        text-align: center;
      }

      .subtitle {
        margin-bottom: 30px;
        text-align: center;

        &::after {
          margin: 0 auto;
        }
      }
    }
  }

  @include breakpoint(720px) {
    &.primary {
      img {
        display: none;
      }

      .infos {
        align-items: center;
      }

      .title {
        text-align: center;

        &::after {
          margin: 0 auto;
        }
      }
    }
  }
}
