@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.post {
  padding: 80px 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .infos {
    width: calc(50% - 20px);

    h3 {
      line-height: 40px;
      color: #000;
      font-size: 34px;
      font-weight: $font-weight-title;
    }

    h4 {
      line-height: 40px;
      color: #000;
      font-size: 32px;
      text-transform: uppercase;
    }

    p {
      margin-top: 20px;
      line-height: 22px;
      font-size: 18px;
    }

    .button {
      margin-top: 30px;
    }
  }

  .image {
    max-width: 50%;
  }

  @include breakpoint(768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }

    .image {
      max-width: 100%;
      margin-bottom: 30px;
    }

    .infos {
      order: 2;
      width: 100%;
    }
  }
}
