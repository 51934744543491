@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.menuItem {
  flex: 1;
  display: flex;
  list-style: none;

  &.active {
    background-color: #ededed;
  }

  .link {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: "Stihl";
    font-weight: $font-weight-title;
    text-decoration: none;
    color: #000;
    border-right: 1px solid #b1b1b1;
    cursor: pointer;
  }

  .icon {
    margin-left: 10px;
  }

  @include breakpoint(960px) {
    .link {
      padding: 10px;
    }
  }

  @include breakpoint(768px) {
    .link {
      border-top: 1px solid #b1b1b1;
      border-right: 0;
    }
  }
}
