@use "../../../styles/variables" as *;

.squareItem {
  flex: 1;
  display: flex;
  flex-direction: column;

  .image {
    img {
      width: 100%;
    }
  }

  .infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    border: 1px solid #f5f5f5;
    border-top: 0;
  }

  .title {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: $font-weight-title;
    line-height: 20px;
  }

  .description {
    margin: 5px 0 20px;
    line-height: 20px;
    color: $secondary-color;
    font-size: 16px;

    span {
      font-size: 12px;
      font-weight: $font-weight-bold;
    }
  }

  .button {
    margin-top: auto;
  }
}
