@use "../../../styles/variables" as *;

.breadCrumb {
  display: flex;
  flex-wrap: wrap;
  color: #000;

  .icon {
    margin: 0 10px;
    font-size: 12px;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: $font-weight-bold;
    font-size: 15px;
  }

  .link {
    font-weight: $font-weight-normal;
    color: inherit;
    text-decoration: none;
  }

  &.light {
    color: #fff;
  }
}
