@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.tips {
  padding-bottom: 50px;

  .list {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    flex-direction: column;
    width: 33.33%;
  }

  @include breakpoint(768px) {
    .item {
      width: 50%;
    }
  }
}
