@use "../../../styles/variables" as *;

.iconBall {
  display: inline-flex;
  padding: 15px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  background-color: $secondary-color;
}
