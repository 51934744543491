@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.tipDetail {
  padding: 20px 0;

  @include breakpoint(640px) {
    .tipData {
      flex-direction: column;
    }
    .tipInfos {
      width: 100%;
    }
  }
}
