@use "../../../styles/variables" as *;

.midias {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;

  .icon {
    font-size: 26px;
    color: #fff;
    max-width: 22px;
    max-height: 26px;
  }
  .iconx {
    width: 22px;
    height: 26px;
  }
}
