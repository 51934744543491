@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.storesList {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: column;
    width: calc(25% - 20px);
    margin: 0 10px 50px;
  }

  @include breakpoint(960px) {
    .item {
      width: calc(33.33% - 20px);
    }
  }

  @include breakpoint(768px) {
    .item {
      width: calc(50% - 10px);
      margin: 0 5px 50px;
    }
  }

  @include breakpoint(480px) {
    .item {
      width: 100%;
      margin: 0 0 50px;
    }
  }
}
