@use "../../../styles/variables" as *;

.contactInfoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  &.hasOnClick {
    cursor: pointer;
  }

  a{
    text-decoration: underline;
    text-decoration-color: $primary-color;
    text-align: center;
  }

  .icon {
    margin-bottom: 15px;
  }

  h5 {
    line-height: 24px;
    font-size: 17px;
  }

  strong {
    margin-bottom: 10px;
    line-height: 24px;
    font-size: 17px;
    font-weight: $font-weight-title;
    color: $primary-color;
  }

  p {
    line-height: 20px;
    text-align: center;
    font-size: 14px;
  }
}
