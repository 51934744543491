@use "../../../styles/variables" as *;

.videoItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;

  .image {
    position: relative;
    transition: 0.5s;

    img {
      max-width: 100%;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      color: #fff;
      font-size: 30px;
      background-color: $primary-color;
    }
  }

  .title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    border-bottom: 1px solid $line-separator;
    text-align: left;
    color: $font-color-default;
    transition: 0.5s;

    p {
      padding-right: 15px;
      line-height: 20px;
      font-size: 15px;
      font-weight: $font-weight-title;
    }
  }

  &:hover {
    .image {
      opacity: 0.5;

      .play {
        display: flex;
      }
    }

    .title {
      color: $primary-color;
    }
  }
}
