@use "../../../styles/variables" as *;

.accessoryItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border-bottom: 1px solid $line-separator;

  .image {
    position: relative;

    img {
      max-width: 100%;
    }
  }

  .infos {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  strong {
    margin-top: 15px;
    line-height: 22px;
    font-size: 19px;
    font-weight: $font-weight-title;
    color: $font-color-default;
    text-align: left;

    &::after {
      content: "";
      display: block;
      width: 15px;
      height: 3px;
      margin-top: 5px;
      background-color: #000;
    }
  }

  p {
    margin-top: 15px;
    line-height: 20px;
    font-size: 15px;
    text-align: left;
  }

  .small {
    font-size: 12px;
    font-style: italic;
  }

  .appliance {
    margin: 20px 0 30px;
    line-height: 20px;
    font-size: 15px;
    font-weight: $font-weight-title;
    text-align: left;

    span {
      color: $primary-color;
    }
  }

  .button {
    margin-top: auto;

    .icon {
      margin-right: 10px;
      color: $primary-color;
    }

    span {
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #fff;
        transition: 0.5s;
      }
    }

    &:hover {
      span {
        &::after {
          border-bottom: 1px solid $primary-color;
        }
      }
    }
  }
}
