@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.modalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.7);
  z-index: 9;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 90%;
  background-color: #fff;
  z-index: 10;

  .modalContent {
    flex: 1;
    overflow: auto;
  }

  .close {
    position: absolute;
    left: 100%;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    font-size: 22px;
    color: #fff;
    background-color: #000;
  }

  @include breakpoint(480px) {
    width: calc(100% - 20px);

    .close {
      right: 0;
      bottom: 100%;
      left: unset;
      top: unset;
    }
  }
}
