@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.banner {
  // display: none !important;
  .item {
    position: relative;
  }

  .container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding-bottom: 65px;

    &.isAlignRight {
      justify-content: flex-end;
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    h1 {
      line-height: 42px;
      font-size: 40px;
      font-weight: $font-weight-title;
      text-align: left;
      color: #fff;

      &::after {
        content: "";
        display: block;
        width: 30px;
        height: 5px;
        margin-top: 15px;
        background-color: #fff;
      }
    }

    .button {
      margin-top: 25px;
    }

    .icon {
      margin-right: 15px;
    }
  }

  .arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border: 0;
    border-radius: 50%;
    color: #fff;
    background-color: #000;
    z-index: 2;

    &:hover {
      background-color: $primary-color;
    }

    &.arrowLeft {
      left: 10px;
    }

    &.arrowRight {
      right: 10px;
    }
  }

  :global {
    .carousel {
      .control-dots {
        bottom: 20px;
        margin: 0;

        .dot {
          width: 10px;
          height: 10px;
          margin: 0 5px;
          opacity: 1;

          &.selected {
            background-color: $primary-color;
          }
        }
      }
    }
  }

  @include breakpoint(1280px) {
    .container {
      padding: 0 50px 65px;
    }
  }

  @include breakpoint(1100px) {
    .infos {
      h1 {
        line-height: 28px;
        font-size: 25px;

        &::after {
          width: 25px;
          height: 3px;
          margin-top: 5px;
        }
      }
    }
  }

  @include breakpoint(768px) {
    .container {
      padding: 0 20px 40px;
    }

    .arrows {
      display: none;
    }

    .infos {
      width: 100%;
    }

    :global {
      .carousel {
        .control-dots {
          bottom: 10px;
        }
      }
    }
  }
}
