@use "../../../styles/variables" as *;

.tipInfos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .infosTitle {
    line-height: 37px;
    font-size: 35px;
    font-weight: $font-weight-title;

    &:after {
      content: "";
      display: block;
      width: 28px;
      height: 6px;
      margin-top: 5px;
      background-color: #000;
    }
  }
  .infosResume {
    font-size: 30px;
    font-style: italic;
    color: #000;
    line-height: 45px;
    margin-bottom: 30px;
  }
  .infosDate {
    font-size: 14px;
    line-height: 1.42857;
  }
  .line {
    margin-top: 40px; 
    border-top: 1px solid #d1d1d1;
    padding:0px 0 20px;
    display: block;
    width: 100%;
  }
  .infosList {
    strong {
      font-size: 20px;
      font-weight: 900;
      font-family: "Stihl";
      text-transform: uppercase;
    }
    ul {
      padding-left: 20px;
      margin-top: 30px;
      li {
        font-size: 16px;
        padding: 6px;
        p {
          margin-top: 15px;
        }
      }
    }
    div {
      p {
        margin-top: 20px;
        
        a {
          text-decoration: none;
          color: #f37a1f;
        }
      }
    }
  }
  .infosImage {
    margin: 0 auto;
    display: block;
    width: 900px;
    height: 500px;
    object-fit: cover;
    margin-top: 50px;
  }
  .infosDescription {
    margin-top: 30px;
    line-height: 24px;
    font-size: 18px;
    a {
      text-decoration: none;
      color: #f37a1f;
    }
   
    span {
      display: block;
      margin-top: 5px;
      font-style: italic;
      font-size: 30px;
    }
    strong {
      font-size: 20px;
      font-weight: 900;
      font-family: "Stihl";
      text-transform: uppercase;
    }
    
  }

  .infosButton {
    margin-top: 40px;
  }
 
}
