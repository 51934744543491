@use "../../../styles/variables" as *;

.headerSquareItems {
  padding: 60px 0 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-color: $primary-color;

  &.primary {
    .infos {
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        letter-spacing: -1px;
        color: #fff;
        font-size: 24px;
        font-weight: $font-weight-title;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
