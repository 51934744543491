@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.technicalTable {
  .title {
    line-height: 32px;
    font-size: 28px;
    font-weight: $font-weight-title;

    &:after {
      content: "";
      display: block;
      width: 21px;
      height: 5px;
      margin-top: 5px;
      background-color: #000;
    }
  }

  .table {
    margin-top: 25px;
  }

  .row {
    display: flex;
    background-color: #ededed;

    &:nth-child(even) {
      background-color: #fff;
    }
  }

  .col {
    flex: 1;
    padding: 15px 25px;
    font-size: 15px;
    border-right: 1px solid #000;

    &:last-child {
      border: 0;
    }
  }

  @include breakpoint(540px) {
    .col {
      padding: 10px;
      font-size: 14px;
    }
  }
}
