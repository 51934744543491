@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.modalLegalInformation {
  padding: 30px;

  p {
    margin-bottom: 15px;

    a {
      color: $primary-color;
    }

    .link {
      color: $primary-color;
      text-decoration: underline;
    }

    .cooliesLink {
      color: $primary-color !important;
      text-decoration: underline !important;
      border: none !important;
      padding: 0 !important;
      font-size: inherit !important;
      line-height: inherit !important;

      &:hover {
        color: $primary-color !important;
        background-color: initial !important;
      }
    }
  }

  .cookiesList {
    margin-top: 20px;
  }

  .cookieItem {
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
      font-size: 14px;
    }

    strong {
      font-weight: $font-weight-bold;
    }
  }
}
