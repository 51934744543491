@use "../../../styles/variables" as *;

.input {
  height: 60px;
  padding: 0 20px;
  border: 0;
  font-size: 18px;
  font-family: $font-family-default;
  background-color: #ededed;

  &.hasError {
    border: 1px solid $error-color;
  }
}
