@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.attributesList {
  .title {
    line-height: 32px;
    font-size: 28px;
    font-weight: $font-weight-title;

    &:after {
      content: "";
      display: block;
      width: 21px;
      height: 5px;
      margin-top: 5px;
      background-color: #000;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
  }

  .item {
    display: flex;
    flex-direction: column;
    width: calc(25% - 15px);
    margin-right: 20px;
    margin-bottom: 30px;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @include breakpoint(768px) {
    .item {
      width: calc(33% - 10px);
      margin-right: 17px;

      &:nth-child(4n) {
        margin-right: 17px;
      }

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
  }

  @include breakpoint(540px) {
    .item {
      width: calc(50% - 10px);
      margin-right: 17px;

      &:nth-child(4n) {
        margin-right: 17px;
      }

      &:nth-child(3n) {
        margin-right: 17px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
