@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.contact {
  padding: 50px 0;

  .description {
    line-height: 25px;
    font-size: 19px;

    strong {
      font-weight: $font-weight-title;
    }
  }

  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
  }

  .formGroup {
    width: calc(50% - 10px);
  }

  .customFields {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 10px);
  }

  .smallText {
    width: 100%;
    margin-bottom: 30px;
    font-size: 14px;

    span {
      color: $primary-color;
    }
  }

  .confirmationMessage {
    padding: 50px;
    line-height: 30px;
    font-size: 24px;
    font-weight: $font-weight-title;
    color: $primary-color;
  }

  @include breakpoint(768px) {
    .customFields {
      width: 100%;
    }
  }

  @include breakpoint(480px) {
    .formGroup {
      width: 100%;
    }

    .customFields {
      flex-direction: column;
    }
  }
}
