@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.attributeBox {
  display: flex;
  flex-direction: column;

  .image {
    position: relative;
    padding-top: 100%;
    border: 1px solid #f3f3f3;
    transition: 0.5s;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
    }
  }

  .title {
    margin-top: 15px;
    line-height: 24px;
    font-size: 16px;
    font-weight: $font-weight-title;
    transition: 0.3s;
  }

  .description {
    flex: 1;
    margin-top: 10px;
    line-height: 24px;
    font-size: 15px;
  }

  @include breakpoint(540px) {
    .title {
      line-height: 16px;
      font-size: 13px;
    }
  }
}
