@use "../../../styles/variables" as *;

.loadOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.7);
  z-index: 9;

  .loader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 10;
  }
}
