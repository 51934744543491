@font-face {
  font-family: "Stihl";
  src: url("../assets/fonts/STIHLContrafaceDisplayTitling.woff2")
    format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Stihl";
  src: url("../assets/fonts/STIHLContrafaceText-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Stihl";
  src: url("../assets/fonts/STIHLContrafaceText.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
