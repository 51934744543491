@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.submenu {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 3;

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 150px 30px 20px;
    border-bottom: 1px solid #b1b1b1;
  }

  .list {
    width: 100%;
    columns: 2;
  }

  .title {
    line-height: 20px;
    font-size: 16px;
    font-weight: $font-weight-title;
  }

  .item {
    padding: 20px 0 0;
  }

  .link {
    line-height: 20px;
    font-size: 13px;
    color: #000;
    text-decoration: none;

    &:hover {
      color: #f37a1f;
      text-decoration: underline;
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border: 0;
    color: #fff;
    background-color: #000;
    transition: 0.3s;

    &:hover {
      background-color: #f37a1f;
    }
  }

  .all {
    width: 100%;
    padding: 30px 0;

    .allLink {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 13px;
      font-weight: bold;
      text-decoration: none;

      .allLinkIcon {
        margin-left: 10px;
      }
    }
  }

  @include breakpoint(768px) {
    .container {
      padding-right: 20px;
    }

    .close {
      right: 0;
    }

    .all {
      padding: 15px 0;
    }
  }

  @include breakpoint(580px) {
    .list {
      columns: 1;
    }
  }
}
