@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.roundItems {
  .list {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: -150px;
    z-index: 1;

    .item {
      width: 30%;
      max-width: 300px;
    }
  }

  @include breakpoint(768px) {
    .list {
      flex-wrap: wrap;
      justify-content: space-around;

      .item {
        width: 45%;
        max-width: none;
      }
    }
  }

  @include breakpoint(480px) {
    .list {
      .item {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}
