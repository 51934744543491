@use "../../../styles/variables" as *;

.accessoriesSlider {
  .title {
    line-height: 32px;
    font-size: 28px;
    font-weight: $font-weight-title;

    &:after {
      content: "";
      display: block;
      width: 21px;
      height: 5px;
      margin-top: 5px;
      background-color: #000;
    }
  }

  .slider {
    padding: 0 45px;
  }

  .arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border: 0;
    border-radius: 50%;
    color: #fff;
    background-color: $primary-color;
    z-index: 2;

    &:hover {
      background-color: $secondary-color;
    }

    &.arrowLeft {
      right: calc(100% + 10px);
    }

    &.arrowRight {
      left: calc(100% + 10px);
    }
  }

  :global {
    .carousel {
      overflow: visible;

      .slide {
        display: flex;
        padding: 0 10px;
      }
    }
  }
}
