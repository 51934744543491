@use "../../../styles/variables" as *;

.roundItem {
  .image {
    position: relative;
    padding-top: calc(100% - 20px);
    border: 10px solid #fff;
    border-radius: 50%;
    overflow: hidden;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      border-radius: 50%;
    }
  }

  .infos {
    padding: 20px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: $font-weight-title;
    line-height: 20px;
    text-align: center;

    &::after {
      content: "";
      display: block;
      width: 34px;
      height: 5px;
      margin-top: 10px;
      background-color: $primary-color;
    }
  }

  .subtitle {
    margin-top: 15px;
    line-height: 24px;
    color: $secondary-color;
    font-size: 18px;
    font-weight: $font-weight-bold;
  }

  .description {
    margin-top: 5px;
    line-height: 24px;
    color: $secondary-color;
    font-size: 16px;

    span {
      font-size: 12px;
      font-weight: $font-weight-bold;
    }
  }

  .listItems {
    margin-top: 20px;

    .listItem {
      margin-top: 5px;
      font-size: 18px;

      b {
        font-weight: $font-weight-bold;
      }
    }
  }

  &.alignLeft {
    .title {
      align-items: flex-start;
      text-align: left;
    }
  }

  &.primary {
    .title {
      color: #000;

      &::after {
        background-color: $primary-color;
      }
    }
  }

  &.secondary {
    .title {
      &::after {
        width: 24px;
        height: 4px;
        background-color: #000;
      }
    }
  }

  &.tertiary {
    .title {
      color: #000;

      &::after {
        width: 24px;
        height: 4px;
        background-color: #000;
      }
    }
  }
}
