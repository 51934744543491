@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.menu {
  position: relative;
  border-top: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .logo {
    display: block;
    padding: 20px 16px;
    background-color: $primary-color;

    .svg {
      width: 115px;
      fill: #fff;
    }
  }

  .nav {
    flex: 1;
    display: flex;
  }

  .menuItem {
    justify-content: center;
  }

  .icon {
    margin-right: 10px;
  }

  .menuItemExternal {
    color: $primary-color;
  }

  @include breakpoint(960px) {
    .logo {
      padding: 10px;

      .svg {
        width: 100px;
      }
    }
  }

  @include breakpoint(768px) {
    .container {
      padding: 0;
    }

    .menuItem {
      border-top: 0;
      border-right: 1px solid #b1b1b1;
    }
  }

  @include breakpoint(640px) {
    .menuItemText {
      display: none;
    }

    .icon {
      margin: 0;
    }
  }

  @include breakpoint(480px) {
    .logo {
      .svg {
        width: 70px;
      }
    }
  }
}
