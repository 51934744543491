@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.headerLinks {
  position: relative;
  border-top: 3px solid $primary-color;
  background-color: #ededed;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    font-size: 14px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    text-decoration: none;

    &:last-child {
      margin-left: 40px;
    }

    .icon {
      margin-right: 10px;
    }
  }

  @include breakpoint(580px) {
    .container {
      justify-content: space-between;
      padding: 5px 20px;
    }

    .item {
      font-size: 12px;

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
