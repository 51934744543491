@use "../../../styles/variables" as *;

.storeItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-bottom: 1px solid $line-separator;

  .title {
    line-height: 20px;
    font-size: 16px;
    font-weight: $font-weight-title;
  }

  .infos {
    margin: 10px 0 20px;

    p {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .button {
    margin-top: auto;
    color: $primary-color;

    .icon {
      margin-right: 10px;
    }
  }
}
