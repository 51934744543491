@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.whereToFindForm {
  padding: 10px 0 0;

  .title {
    margin-bottom: 35px;
    font-size: 30px;
    font-weight: $font-weight-title;

    &::after {
      content: "";
      display: block;
      width: 24px;
      height: 5px;
      margin-top: 5px;
      background-color: #000;
    }
  }

  .description {
    line-height: 25px;
    font-size: 19px;
  }

  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px 0;
  }

  .formGroup {
    width: calc(50% - 10px);
  }

  @include breakpoint(480px) {
    .form {
      flex-direction: column;
    }

    .formGroup {
      width: 100%;
    }
  }
}
