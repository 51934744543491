@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.productBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;
  padding: 20px;
  border-bottom: 1px solid $line-separator;
  cursor: pointer;

  .image {
    position: relative;
    margin-bottom: 10px;
    padding-top: 100%;
    transition: 0.5s;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 300px;
    }
  }

  .flag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 23px;
    margin-bottom: 10px;
  }

  .title {
    max-width: 250px;
    font-size: 16px;
    font-weight: $font-weight-title;
    transition: 0.3s;
  }

  .description {
    flex: 1;
    margin-top: 10px;
    line-height: 15px;
    font-size: 15px;
  }

  .button {
    margin: 20px 0 0 auto;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      border-radius: 50%;
      color: #fff;
      font-size: 13px;
      background-color: $secondary-color;
      transition: 0.5s;

      svg {
        margin: 0;
      }
    }
  }

  &:hover {
    .image {
      opacity: 0.3;
    }

    .title {
      color: $primary-color;
    }

    .button {
      .icon {
        background-color: $primary-color;
      }
    }
  }

  @include breakpoint(480px) {
    margin: 5px;
    padding: 15px 5px;
  }
}
