@use "../../../styles/variables" as *;

.footer {
  padding: 0 0 45px;
  background-color: #f9f9f9;

  .container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
 strong {
   font-weight: bold;
 }
  .newsletter {
    width: 100%;
  }

  .block {
    max-width: 300px;
  }

  .line {
    margin: 35px 0;
  }

  .smallText {
    line-height: 18px;
    font-size: 12px;
    color: #666;

    p {
      margin-bottom: 10px;
    }
  }

  .link {
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
  }
}
