@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.videos {
  padding: 60px 0;
  background: url("../../../assets/images/bg-videos.jpg") top center no-repeat;
  background-size: contain;

  .container {
    padding-left: 60px;
    padding-right: 60px;
  }

  h3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    line-height: 30px;
    text-align: center;
    font-size: 24px;
    font-weight: $font-weight-title;
    color: #ffffff;
  }

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .videoItem {
    height: 100%;
    margin: 0 20px;
  }

  .arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border: 0;
    border-radius: 50%;
    color: #fff;
    background-color: $primary-color;
    z-index: 2;

    &:hover {
      background-color: $secondary-color;
    }

    &.arrowLeft {
      right: calc(100% + 10px);
    }

    &.arrowRight {
      left: calc(100% + 10px);
    }
  }

  .seeAll {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  :global {
    .carousel {
      overflow: visible;
    }
  }

  @include breakpoint(1400px) {
    background-size: auto 270px;
  }

  @include breakpoint(580px) {
    .container {
      padding-left: 50px;
      padding-right: 50px;
    }

    .videoItem {
      margin: 0;
    }
  }
}
