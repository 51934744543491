@use "../../../styles/variables" as *;

.button {
  display: inline-flex;
  align-items: center;
  border: 0;
  color: #fff;
  font-family: $font-family-default;
  font-weight: $font-weight-title;
  background-color: $primary-color;
  text-decoration: none;
  transition: 0.5s;

  &.secondary {
    background-color: $secondary-color;

    &:hover {
      background-color: $primary-color;
    }
  }

  &.tertiary {
    background-color: transparent;
    border: 2px solid #fff;

    &:hover {
      background-color: $secondary-color;
      border: 2px solid $secondary-color;
    }
  }

  &.md {
    padding: 20px 30px;
    font-size: 15px;
  }

  &.sm {
    padding: 15px 25px;
    font-size: 12px;
  }

  &.link {
    padding: 0;
    font-size: 14px;
    color: $font-color-default;
    background: none;

    &.secondary {
      font-size: 13px;
      font-weight: $font-weight-normal;
    }

    &:hover {
      color: $primary-color;
      background: none;
    }
  }

  &:hover {
    background-color: $secondary-color;
  }
}
