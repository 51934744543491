@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.accessoriesList {
  padding-bottom: 50px;

  .list {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 20px);
    margin-bottom: 50px;

    &.bigger {
      width: calc(50% - 20px);
    }
  }

  @include breakpoint(768px) {
    .item {
      width: calc(50% - 10px);

      &.bigger {
        width: calc(50% - 10px);
      }
    }
  }

  @include breakpoint(480px) {
    .item {
      width: 100%;

      &.bigger {
        width: 100%;
      }
    }
  }
}
