$font-family-default: Stihl;
$font-color-default: #000;

$font-weight-title: 900;
$font-weight-bold: bold;
$font-weight-normal: normal;

$primary-color: #f37a1f;
$secondary-color: #000;
$error-color: #e50000;

$line-separator: #b1b1b1;
