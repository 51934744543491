@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.headerRoundItems {
  padding: 60px 0 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

  &.primary {
    .infos {
      max-width: 445px;
      margin-left: auto;
      padding: 45px;
      background-color: #fff;

      h3 {
        letter-spacing: -1px;
        font-weight: $font-weight-title;
        font-size: 33px;
        color: #000;

        &::after {
          content: "";
          display: block;
          width: 30px;
          height: 5px;
          margin-top: 10px;
          background-color: #000;
        }
      }

      p {
        margin-top: 20px;
        letter-spacing: -1px;
        font-weight: $font-weight-title;
        font-size: 19px;
        color: $primary-color;
      }
    }
  }

  &.secondary {
    .infos {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 50px 0;
      line-height: 37px;
      font-size: 22px;
      text-align: center;
      color: #ffffff;
      font-weight: $font-weight-title;

      p {
        padding: 0 10px;
        background-color: $primary-color;
      }
    }
  }

  &.tertiary {
    .infos {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -1px;
      color: #000;
      font-size: 30px;
      text-transform: uppercase;

      h3 {
        font-weight: $font-weight-title;
      }

      p {
        margin-left: 10px;
        padding-bottom: 3px;
      }
    }
  }

  @include breakpoint(960px) {
    &.tertiary {
      .infos {
        flex-direction: column;
      }
    }
  }

  @include breakpoint(768px) {
    background-attachment: initial;
  }
}
