@use "../../../styles/variables" as *;

.menuButton {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: "Stihl";
  font-weight: $font-weight-title;
  color: #000;
  cursor: pointer;

  &.isOpen {
    background-color: #ededed;
  }
}
