@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.modalVideo {
  position: relative;
  width: 70vw;
  max-width: 100%;
  padding-top: 56%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include breakpoint(480px) {
    width: 100%;
  }
}
