@use "../../../styles/variables" as *;

.flag {
  display: inline-flex;
  color: #fff;
  font-size: 12px;
  font-weight: $font-weight-bold;
  background-color: $primary-color;

  &.sm {
    padding: 5px 10px;
  }

  &.md {
    padding: 10px 15px;
  }
}
