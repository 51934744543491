@use "../../../styles/variables" as *;

.productInfos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .infosFlag {
    margin-bottom: 10px;
  }

  .infosTitle {
    line-height: 37px;
    font-size: 35px;
    font-weight: $font-weight-title;

    &:after {
      content: "";
      display: block;
      width: 28px;
      height: 6px;
      margin-top: 5px;
      background-color: #000;
    }
  }

  .infosCode {
    margin-top: 15px;
    line-height: 20px;
    font-size: 16px;
  }

  .infosDescription {
    margin-top: 30px;
    line-height: 24px;
    font-size: 18px;

    
  }
  span {
    display: block;
    margin-top: 5px;
    line-height: 15px;
    font-style: italic;
    font-size: 13px;
  }
  .infosButton {
    margin-top: 40px;
  }

  .infosIcon {
    margin-right: 15px;
  }
}
