@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.productDetail {
  padding: 50px 0;

  .productData {
    display: flex;
    justify-content: space-between;
  }

  .productGallery {
    width: calc(50% - 25px);
  }

  .productInfos {
    width: calc(50% - 25px);
  }

  .technicalDetail {
    margin-top: 55px;
  }

  .attributesList {
    margin-top: 55px;
  }

  .accessoriesSlider {
    margin-top: 55px;
  }

  @include breakpoint(640px) {
    .productData {
      flex-direction: column;
    }

    .productGallery {
      width: 100%;
      margin-bottom: 30px;
    }

    .productInfos {
      width: 100%;
    }
  }
}
