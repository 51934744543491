@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.menu {
  position: relative;
  border-top: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .logo {
    display: block;
    padding: 20px 16px;
    background-color: $primary-color;

    .svg {
      width: 115px;
      fill: #fff;
    }
  }

  .nav {
    flex: 1;
    display: flex;
  }

  @include breakpoint(960px) {
    .logo {
      padding: 10px;

      .svg {
        width: 100px;
      }
    }
  }

  @include breakpoint(768px) {
    .container {
      padding: 0;
    }

    .nav {
      position: absolute;
      top: 100%;
      left: 0;
      flex: none;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid #b1b1b1;
      background-color: #fff;
      z-index: 3;
    }
  }
}
