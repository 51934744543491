@use "../../../styles/variables" as *;

.textarea {
  min-height: 200px;
  padding: 20px;
  border: 0;
  font-size: 18px;
  font-family: $font-family-default;
  background-color: #ededed;
  resize: none;

  &.hasError {
    border: 1px solid $error-color;
  }
}
