@use "../../../styles/variables" as *;

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;

  label {
    margin-bottom: 10px;
    font-size: 14px;

    &.isMandatory {
      &::after {
        content: " *";
        color: $primary-color;
      }
    }
  }
}
