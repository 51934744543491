@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  padding: 50px 20px;
  background-color: $primary-color;

  .title {
    line-height: 34px;
    font-weight: $font-weight-title;
    font-size: 28px;
    text-align: center;
    color: #fff;
  }

  .subtitle {
    line-height: 34px;
    font-size: 21px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 820px;
    margin-top: 25px;
  }

  .fields {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .input {
    flex: 1;
    max-width: calc(50% - 25px);
    height: 40px;
    padding: 0 10px;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-weight: $font-weight-title;
    background: none;

    &::placeholder {
      font-size: 16px;
      font-family: $font-family-default;
      font-weight: $font-weight-title;
      color: rgba(#fff, 0.7);
    }
  }

  .button {
    margin-top: 40px;
  }

  .midias {
    margin-top: 40px;
  }

  .confirmationMessage {
    padding: 50px;
    line-height: 30px;
    font-size: 24px;
    font-weight: $font-weight-title;
    color: $primary-color;
  }

  @include breakpoint(480px) {
    .fields {
      flex-direction: column;
    }

    .input {
      max-width: none;
      margin-bottom: 15px;
    }
  }
}
