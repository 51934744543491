@use "./variables" as *;
@use "./mixins" as *;
@use "./fonts";
@use "./reset";

body {
  font-family: "Stihl";
  color: $font-color-default;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

@include breakpoint(768px) {
  .container {
    padding: 0 10px;
  }
}

#map {
  display: none !important;
}