@use "../../../styles/variables" as *;

.headerPage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  h1 {
    margin-top: 35px;
    font-size: 38px;
    font-weight: $font-weight-title;

    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 7px;
      margin-top: 10px;
      background-color: #000;
    }
  }

  .icon {
    margin-right: 10px;
  }

  &.hasBackground {
    h1 {
      margin-top: 60px;
      font-size: 47px;
    }
  }

  &.light {
    color: #fff;

    h1 {
      &::after {
        background-color: #fff;
      }
    }
  }
}
